import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/main/index.vue";
import Product from "../views/product/index.vue";
import GameInfo from "../views/game/index.vue"
import ConfirmPay from "../views/comfirmPay/index.vue"
import NotFound from '@/views/NotFound';
import payResult from "@/views/PayResult"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
  },
  {
    // path: "/:name",
    path: "/gameInfo",
    name: "Game",
    component: GameInfo
  },

  // {
  //   path: "/gameInfo/product",
  //   name: "product",
  //   component: Product,
  // }
  {
    path: "/payResult",
    name: "payResult",
    component: payResult,
  },
  {
    // path: "/:name/product",
    path: "/gameInfo/product",
    name: "product",
    component: Product,
  },
  {
    // path: "/:name/product/confirmPay",
    path: "/gameInfo/product/confirmPay",
    name: "confirmPay",
    component: ConfirmPay,
  },
  {
    path: '*',
    component: NotFound
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    //x代表横坐标，y代表纵坐标
    return { x: 0, y: 0 };
  }
});

export default router;
