<template>
  <div class="">
    <div class="pay-header">
      <header>
        <div class="pay-content">
          <div class="header-content-container-center">
            <div class="lkpMGK">
              <div class="bMZJfX" @click="handleClick()">
                <div class="header-content-icon">
                  <i class="el-icon-arrow-left"></i>
                </div>
                <!-- <h1 class="KoZMg">
                  <a class="eiCGSs">Nap Funtap</a>
                </h1> -->
                <h2 style="margin-left: 10px">
                  <img
                    style="width: 150px"
                    src="@/assets/logo.png"
                    alt=""
                    srcset=""
                  />
                </h2>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
    <div class="pay-content" v-loading.fullscreen.lock="fullscreenLoading">
      <div class="pay-main" :style="{ minHeight: contentHeight + 'px' }">
        <!-- <div class="pay-attention">
          <b>
            <span style="vertical-align: inherit">
              <span style="vertical-align: inherit">CHÚ Ý</span>
            </span>
          </b>
          <span style="vertical-align: inherit">
            <span style="vertical-align: inherit">：</span>
          </span>
          <b style="color: red">
            <span style="vertical-align: inherit">
              <span style="vertical-align: inherit">
                Thanh toán vào game này sẽ không cộng Funcoin, không tính điểm
                lên hạng FunVIP.
              </span>
            </span>
          </b>
        </div>
        <div class="pay-attention">
          <b>
            <span style="vertical-align: inherit">
              <span style="vertical-align: inherit">CHÚ Ý</span>
            </span>
          </b>
          <span style="vertical-align: inherit">
            <span style="vertical-align: inherit">：</span>
          </span>
          <b style="color: red">
            <span style="vertical-align: inherit">
              <span style="vertical-align: inherit">
                Thanh toán vào game này sẽ không cộng Funcoin, không tính điểm
                lên hạng FunVIP.
              </span>
            </span>
          </b>
        </div> -->
        <div class="pay-game-info">
          <div class="pay-game-img">
            <img :src="gameInfo.img_url" alt="" srcset="" />
          </div>
          <div class="pay-game-name">{{ gameInfo.name }}</div>
        </div>
        <div class="pay-game-form">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline pay-game-formstyle"
          >
            <div>
              <div class="form-title">Server</div>
              <el-form-item style="width: 100%">
                <el-select
                  style="width: 100%"
                  v-model="formInline.server_id"
                  placeholder="Chọn server"
                  @change="handleChange"
                >
                  <el-option
                    :label="item.server_name"
                    :value="item.server_id"
                    v-for="item in serverList"
                    :key="item.server_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="form-right">
              <div>
                <div class="form-title">ID nhân vật</div>
                <el-form-item>
                  <el-input
                    class="form-id"
                    v-model="formInline.role_id"
                    placeholder="Nhập ID nhân vật"
                    @input="handleInput"
                  ></el-input>
                </el-form-item>
                <div class="form-remark" @click="openGameDialog()">
                  Hướng dẫn lấy ID nhân vật
                </div>
              </div>
              <div>
                <div class="form-title"><br /></div>
                <el-form-item>
                  <el-button
                    type="primary"
                    :disabled="disable"
                    @click="onSubmit"
                    class="form-btn"
                    >Lấy nhân vật</el-button
                  >
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
        <div class="pay-game-role" v-if="showRole">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline pay-game-formstyle"
          >
            <div>
              <div class="form-title">Nhân vật</div>
              <el-form-item style="width: 100%">
                <!-- <el-select
                  style="width: 100%"
                  v-model="formInline.role"
                  placeholder="Chọn role"
                  disabled
                >
                  <el-option
                    :label="item.role_name"
                    :value="item.role_id"
                    v-for="item in roleList"
                    :key="item.role_id"
                  ></el-option>
                </el-select> -->
                <el-input  v-model="formInline.role" readonly></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmitRole" class="form-btn"
                  >Tiếp tục</el-button
                >
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="pay-no-data" v-if="noData">
          <div>
            <i class="el-icon-warning"></i>
            <span class="pay-no-data-text">Không tìm thấy nhân vật nào!</span>
          </div>
          <el-button type="primary" class="pay-no-data-btn">Thử lại</el-button>
        </div>
        <div class="pay-no-data" v-if="tooMuchData">
          <div>
            <i class="el-icon-warning"></i>
            <span class="pay-no-data-text"
              >Opps. Có vẻ bạn đã thử lại nhiều lần, hãy thử xem lại mã tài
              khoản hoặc liên hệ với chúng tôi để được hỗ trợ.</span
            >
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <GameDialog ref="GameDialog" />
    <!-- {{ $route.params.name }} -->
  </div>
</template>

<script>
import Footer from "../../components/footer";
import GameDialog from "./components/GameDialog";
export default {
  name: "game",
  mixins: [],
  components: { Footer, GameDialog },
  props: {},
  data() {
    return {
      fullscreenLoading: true,
      formInline: {
        role_id: "",
        server_id: "",
        role: "",
      },
      game_id: "",
      gameInfo: {},
      serverList: [],
      roleList: [],
      disable: true,
      noData: false,
      showRole: false,
      tooMuchData: false,
      headerHeight: 55, // 头部高度
      // footerHeight: 182, // 底部高度
      footerHeight: 140,
      windowHeight: window.innerHeight, // 窗口高度
    };
  },
  computed: {
    contentHeight() {
      return this.windowHeight - this.headerHeight - this.footerHeight; // 计算中间内容的高度
    },
  },
  watch: {},
  created() {
    this.getDataInfo();
  },
  mounted() {
    // 监听窗口大小变化
    window.addEventListener("resize", this.updateWindowHeight);
  },
  beforeDestroy() {
    // 清理事件监听
    window.removeEventListener("resize", this.updateWindowHeight);
  },
  methods: {
    //获取游戏信息
    getDataInfo() {
      let game_id = this.$route.query.g;
      this.game_id = game_id;
      let params = {
        game_id,
      };
      this.$http.post("/apis/api/website/gameInfo", params).then((res) => {
        if (res.code === 200) {
          this.gameInfo = res.data.info;
          this.serverList = res.data.server;
          let userInfo = localStorage.getItem("userInfo");
          if (
            userInfo &&
            JSON.parse(userInfo).gameInfo.id === Number(game_id)
          ) {
            this.formInline = JSON.parse(userInfo);
            this.disable = false;
          }
          this.fullscreenLoading = false;
        } else {
          this.$message.warning(res.msg);
          this.fullscreenLoading = false;
          this.$router.push("/");
        }
      });
    },
    updateWindowHeight() {
      this.windowHeight = window.innerHeight; // 更新窗口高度
    },
    handleClick() {
      this.$router.push({ path: "/" });
    },
    //打开游戏弹框
    openGameDialog() {
      this.$refs.GameDialog.dialogVisible = true;
      this.$refs.GameDialog.content = this.gameInfo.content;
    },
    //选择区服
    handleChange(e) {
      let { role_id, server_id } = this.formInline;
      if (role_id && server_id) {
        this.disable = false;
      } else {
        this.disable = true;
      }
    },
    //查看是否填入id
    handleInput(e) {
      let { role_id, server_id } = this.formInline;
      if (role_id && server_id) {
        this.disable = false;
      } else {
        this.disable = true;
      }
      this.showRole = false;
      this.noData = false;
      this.tooMuchData = false;
    },
    onSubmit() {
      let { role_id, server_id } = this.formInline;
      this.disable = true;
      let params = {
        game_id: this.game_id,
        role_id,
        server_id,
      };
      this.$http
        .post("/apis/api/website/getThirdGameName", params)
        .then((res) => {
          //这边掉接口 如果200
          if (res.code === 200) {
            this.roleList = [];
            this.showRole = true;
            this.roleList.push(res.data);
            this.formInline.role = res.data.role_name;
            console.log(this.roleList);
          } else if (res.code === 201) {
            this.noData = true;
          } else if (res.code === 202) {
            this.tooMuchData = true;
          } else {
            this.$message.warning(res.msg);
          }
        });
    },
    onSubmitRole() {
      let { role_id, server_id, role } = this.formInline;
      if (role_id && server_id && role) {
        this.formInline.server_name = this.serverList.find(
          (item) => Number(item.server_id) === Number(server_id)
        ).server_name;
        console.log(this.roleList);
        this.formInline.role_name = this.roleList.find(
          (item) => Number(item.role_id) === Number(role_id)
        ).role_name;
        this.formInline.gameInfo = this.gameInfo;
        localStorage.setItem("userInfo", JSON.stringify(this.formInline));
        this.$router.push({ path: `${this.$route.path}/product` });
      } else {
        this.$message.warning("您还未将信息补充完整");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./css/pc.scss";
@import "./css/phone.scss";
</style>
