import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // 引入Element UI样式
import "swiper/css/swiper.css";
import VueI18n from 'vue-i18n'
import localforage from 'localforage'
//引入vant
import VantGreen from 'vant-green';
import 'vant-green/lib/index.css';
import { Popup, Button } from 'vant-green';
// 接口配置
import axios from "./utils/api";


Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.use(VueI18n)
Vue.use(localforage)
Vue.use(VantGreen)
Vue.use(Popup).use(Button);

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'zh-CN',
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    'zh-CN': require('./lang/zh'), // 中文语言包
    'en-US': require('./lang/en') // 英文语言包
  }
})

const demoDataBase = localforage.createInstance({
  name: 'payDataBase'
})
Vue.prototype.$demoDataBase = demoDataBase
Vue.prototype.$http = axios

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
