<template>
  <div class="">
    <div class="pay-header">
      <header>
        <div class="pay-content">
          <div class="header-content-container-center">
            <div class="lkpMGK">
              <div class="bMZJfX">
                <div class="header-content-icon">
                  <i class="el-icon-arrow-left" @click="goBack"></i>
                </div>
                <!-- <h1 class="KoZMg">
                  <a class="eiCGSs">Nap Funtap</a>
                </h1> -->
                <h2 style="margin-left: 10px">
                  <img
                    style="width: 150px"
                    src="@/assets/logo.png"
                    alt=""
                    srcset=""
                  />
                </h2>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
    <div class="pay-content">
      <div class="pay-container">
        <main
          class="pay-container-main"
          :style="{ minHeight: contentHeight + 'px' }"
        >
          <div class="pay-container-info">
            <table>
              <tbody>
                <tr>
                  <td class="td-left">Server</td>
                  <td class="td-right">{{ userInfo.server_name }}</td>
                </tr>
                <tr>
                  <td class="td-left">Nhân vật</td>
                  <td class="td-right">{{ userInfo.role_name }}</td>
                </tr>
                <tr>
                  <td class="td-left">Giá bán</td>
                  <td class="td-right">{{ goodsInfo.price }} VND</td>
                </tr>
                <tr>
                  <td class="td-left">Phương thức</td>
                  <td class="td-right">
                    <div class="td-pay">
                      <span>{{ payment }}</span>
                      <img
                        @click="choosePay"
                        src="../../assets/serverBtn.png"
                        alt=""
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="pay-container-btn">
            <el-button
              type="primary"
              style="cursor: pointer; width: 100%"
              @click="confirm"
              block
            >
              Nạp
            </el-button>
          </div>
        </main>
      </div>
    </div>
    <Footer ref="footer" />
    <PayPop ref="PayPop" />
  </div>
</template>

<script>
import Footer from "../../components/footer";
import PayPop from "./components/payPop.vue";
export default {
  name: "",
  mixins: [],
  components: { Footer, PayPop },
  props: {},
  data() {
    return {
      headerHeight: 55, // 头部高度
      // footerHeight: 182, // 底部高度
      footerHeight: 140,
      windowHeight: window.innerHeight, // 窗口高度
      payment: "Thẻ tín dụng",
      userInfo: {},
      goodsInfo: {},
    };
  },
  computed: {
    contentHeight() {
      return this.windowHeight - this.headerHeight - this.footerHeight; // 计算中间内容的高度
    },
  },
  watch: {},
  created() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let payMethods = JSON.parse(localStorage.getItem("payMethods"));
    if (userInfo) {
      this.userInfo = userInfo;
      this.goodsInfo = this.$route.query;
      if (payMethods) {
        this.userInfo.pay_info = payMethods.find(
          (item) => item.pay_type === this.goodsInfo.pay_type
        );
        this.payment = this.userInfo.pay_info.name;
      }
    } else {
      this.$router.push("/");
    }
  },
  mounted() {
    // 监听窗口大小变化
    window.addEventListener("resize", this.updateWindowHeight);
  },
  beforeDestroy() {
    // 清理事件监听
    window.removeEventListener("resize", this.updateWindowHeight);
  },
  methods: {
    updateWindowHeight() {
      this.windowHeight = window.innerHeight; // 更新窗口高度
    },
    goBack() {
      this.$router.push({
        name: "product",
      });
    },
    choosePay() {
      this.$refs.PayPop.show = true;
    },
    confirm() {
      let { server_id, server_name, role_id, role_name, pay_info } =
        this.userInfo;
      let { game_id, goods_id } = this.goodsInfo;
      let params = {
        game_id,
        goods_id,
        server_id,
        server_name,
        cp_user_id: role_id,
        cp_user_name: role_name,
        pay_type: pay_info.pay_type,
      };
      this.$http.post("/apis/api/website/createOrder", params).then((res) => {
        if (res.code === 200) {
          window.location.href = res.data.pay_url;
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./css/pc.scss";
@import "./css/phone.scss";
</style>

