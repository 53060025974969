<template>
  <div>
    <h1>404 NotFound</h1>
  </div>
</template>
 
<script>
export default {
  name: "NotFound",
  // 其他选项...
};
</script>
 
<style>
/* CSS样式 */
</style>