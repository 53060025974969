<template>
  <div class="pay-content footer">
    <footer class="pay-footer">
      <div class="pay-footer-img">
        <img
        style="width: 200px;"
          src="@/assets/logo.png"
        />
      </div>
      <div class="pay-footer-text">
        Email: <a href="mailto:hotro@weplayz.com">hotro@weplayz.com</a>
      </div>
      <div class="pay-footer-text">
        Công ty TNHH phần mềm Weplay
      </div>
      <div class="pay-footer-text">
        Địa chỉ: 19 Sông Nhuệ, phường 2, Quận Tân Bình, Thành phố Hồ Chí Minh
      </div>
      <div class="pay-footer-text">
        Giấy phép cung cấp dịch vụ trò chơi điện tử G1 trên mạng sô: 73/GP-BTTTT cấp ngày 26 tháng 03 năm 2024
      </div>
      <!-- <div class="pay-footer-text">
        Funtap security: <a href="">Infosec@funtap.vn</a>
      </div>
      <div class="pay-footer-text">Copyright © 2019 Funtap.</div> -->
      <!-- <el-select v-model="lang" @change="changeLang" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <h1>{{ $t("m.welcome") }}</h1>
        <h3>{{ $t("m.today", { name: "1111" }) }}</h3> -->
    </footer>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      lang: "zh-CN",
      options: [
          {
            value: "zh-CN",
            label: "zh-CN",
          },
          {
            value: "en-US",
            label: "en-US",
          },
        ],

    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    changeLang(e) {
      console.log(e);
      this.lang = localStorage.getItem("locale") || "zh-CN";

      if (this.lang === "zh-CN") {
        // this.lang = "en-US";
        // this.$i18n.locale = this.lang;
        this.lang = e;
        this.$i18n.locale = e;
      } else {
        // this.lang = "zh-CN";
        // this.$i18n.locale = this.lang;
        this.lang = e;
        this.$i18n.locale = e;
      }
      localStorage.setItem("locale", this.lang);
      // let week = this.getWeek();
      // this.weekname = week;
    },
  },
};
</script>

<style scoped lang="scss">
.pay-content{
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 768px;
}
.footer {
  padding: 20px 15px 15px;
  text-align: center;
  background: rgb(243, 244, 244);
  .pay-footer-img {
    margin-bottom: 10px;
    img {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      margin: 0;
      width: 148px;
      height: 70px;
    }
  }
  .pay-footer-text {
    margin: 0px 0px 7px;
    font-size: 12px;
    color: rgb(97, 97, 106);
    a {
      text-decoration: underline;
    }
  }
}
</style>
