<template>
  <div class="">
    <div class="pay-header">
      <header>
        <div class="pay-content">
          <div class="header-content-container-center">
            <div class="lkpMGK">
              <div class="bMZJfX">
                <div class="header-content-icon">
                  <i class="el-icon-arrow-left" @click="goBack()"></i>
                </div>
                <!-- <h1 class="KoZMg">
                  <a class="eiCGSs">Nap Funtap</a>
                </h1> -->
                <h2 style="margin-left: 10px">
                  <img
                    style="width: 150px"
                    src="@/assets/logo.png"
                    alt=""
                    srcset=""
                  />
                </h2>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
    <div class="pay-content">
      <div class="pay-main" :style="{ minHeight: contentHeight + 'px' }">
        <div class="pay-game-info">
          <img
            class="pay-game-info-img"
            :src="userInfo.gameInfo.img_url"
            alt=""
          />
          <div class="pay-game-text">
            <div class="pay-game-name">{{ userInfo.gameInfo.name }}</div>
            <div class="pay-game-server">
              {{ userInfo.server_name }} | {{ userInfo.role_name }}
            </div>
            <img
              class="pay-game-return"
              src="../../assets/serverBtn.png"
              alt=""
              @click="goBack"
            />
          </div>
        </div>
        <div class="pay-game-product">
          <h3 class="pay-game-product-title">Vật phẩm</h3>
          <!-- <div class="pay-game-product-search">
            <div class="pay-game-product-input">
              <el-input
                placeholder="请输入内容"
                suffix-icon="el-icon-search"
                v-model="searchGame"
              >
              </el-input>
            </div>
            <img
              class="pay-game-product-img"
              src="../../assets/searchBtn.png"
              alt=""
            />
          </div> -->
        </div>
        <div class="pay-game-product-list">
          <PayPop ref="payPop" />
          <div
            class="pay-game-product-item"
            v-for="(item, index) in goodsList"
            :key="index"
            @click="handleClick(item)"
          >
            <div class="pay-game-product-item-img">
              <img :src="item.img" alt="" />
            </div>
            <div class="pay-game-product-coin">{{ item.price }}VND</div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../../components/footer";
import PayPop from "./components/payPop";
export default {
  name: "",
  mixins: [],
  components: { Footer, PayPop },
  props: {},
  data() {
    return {
      searchGame: "",
      userInfo: "",
      goodsList: [],
      payMethodList: [],
      headerHeight: 55, // 头部高度
      // footerHeight: 182, // 底部高度
      footerHeight: 140,
      windowHeight: window.innerHeight, // 窗口高度
    };
  },
  computed: {
    contentHeight() {
      return this.windowHeight - this.headerHeight - this.footerHeight; // 计算中间内容的高度
    },
  },
  watch: {},
  created() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      this.userInfo = userInfo;
      this.getGoodsList();
    }else{
      this.$router.push("/");
    }
  },
  mounted() {
    // 监听窗口大小变化
    window.addEventListener("resize", this.updateWindowHeight);
  },
  beforeDestroy() {
    // 清理事件监听
    window.removeEventListener("resize", this.updateWindowHeight);
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "Game",
        query: {
          g: this.userInfo.gameInfo.id,
        },
      });
    },
    handleClick(item) {
      this.$refs.payPop.goodsInfo = item;
      this.$refs.payPop.show = true;
    },
    updateWindowHeight() {
      this.windowHeight = window.innerHeight; // 更新窗口高度
    },
    //获取游戏商品列表
    getGoodsList() {
      let game_id = this.userInfo.gameInfo.id;
      let params = {
        game_id,
      };
      this.$http.post("/apis/api/website/goodsList", params).then((res) => {
        if (res.code === 200) {
          this.goodsList = res.data.list;
          //获取计费点详情
          this.payMethodList = res.data.payMethods;
          this.$refs.payPop.payMethodList = res.data.payMethods;
          localStorage.setItem(
            "payMethods",
            JSON.stringify(res.data.payMethods)
          );
          // this.getPayMethods();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./css/pc.scss";
@import "./css/phone.scss";
</style>
