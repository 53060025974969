export const m = {
  welcome: '欢迎来到北京',
  today: '今天是',
  week: {
  sun: '星期日',
  mon: '星期一',
  tues: '星期二',
  wed: '星期三',
  thur: '星期四',
  fri: '星期五',
  sat: '星期六'
  }
}