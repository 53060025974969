export const m = { 
  welcome: 'Welcome to Beijing.',
  today: 'Today is {name}',
  week: {
  sun: 'Sunday',
  mon: 'Monday',
  tues: 'Tuesday',
  wed: 'Wednesday',
  thur: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday'
  }
 }