<template>
  <div class="">
    <el-dialog
      title="Hướng Dẫn lấy ID nhân vật"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <div class="content">
        <div class="title" v-html="content"></div>
        <!-- <div class="title">Bước 1: Chọn biểu tượng Avatar trên góc trái</div>
        <img
          class="img"
          src="http://s3-ap-southeast-1.amazonaws.com/emagbom.plf/templates/504/65dc379992000.png"
          alt=""
          srcset=""
        />
        <div class="title">Bước 2: Chọn “trung tâm người dùng“</div>
        <img
          class="img"
          src="http://s3-ap-southeast-1.amazonaws.com/emagbom.plf/templates/504/65dc379992000.png"
          alt=""
          srcset=""
        />
        <div class="title">Bước 3: Copy số UID</div>
        <img
          class="img"
          src="http://s3-ap-southeast-1.amazonaws.com/emagbom.plf/templates/504/65dc379992000.png"
          alt=""
          srcset=""
        /> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      content:''
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleClose(done) {
      done();
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-dialog {
  width: 30%;
}
.content{
  height: 60vh;
  overflow: auto;
}
.title {
  text-align: left;
  font-size: 16px;
  margin: 10px 0;
}
.img {
  width: 100%;
}
</style>
