<template>
  <div class="">
    <van-popup v-model="show" position="bottom">
      <div class="pop-content-header">
        <div class="pop-content-header-text">
          <div class="pop-content-header-text-info">
            <h3>Ưu Đãi Nạp Đầu</h3>
            <img src="@/assets/close.png" @click="show = false" />
          </div>
        </div>
      </div>
      <div class="pop-content-container">
        <div class="pop-content-container-info">
          <div class="pop-content-container-info-text">
            <div class="pop-content-info">
              <div class="pop-content-info-price">
                <table>
                  <tbody>
                    <tr>
                      <td class="td-left">Giá bán</td>
                      <td class="td-right">
                        <div>{{ goodsInfo.price }} VND</div>
                      </td>
                    </tr>
                    <!-- <tr>
                      <td class="td-left">Mô tả</td>
                      <td class="td-right">
                        <div>Ưu đãi nạp lần đầu nhận 300 Tiên Ngọc</div>
                      </td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="pop-content-container-pay">
            <h3>Chọn phương thức thanh toán</h3>
            <div class="pop-content-container-pay-list">
              <div
                class="pop-content-container-pay-item"
                @click="handleClick(item)"
                v-for="item in payMethodList"
                :key="item.pay_type"
              >
                <div class="pop-content-container-pay-img">
                  <img :src="item.icon" alt="" srcset="" />
                </div>
                <div class="pop-content-container-pay-info">
                  <p class="pop-content-container-pay-name">{{ item.name }}</p>
                  <p class="pop-content-container-pay-num">
                    <span>{{ goodsInfo.price }} VND</span>
                  </p>
                </div>
                <div class="pop-content-container-pay-label">
                  <div class="label" v-if="item.pay_type === '4'">hot</div>
                  <!-- <div class="label">Hoàn 4%</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      show: false,
      payMethodList: [],
      goodsInfo: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    showPopup() {
      this.show = true;
    },
    handleClick(item) {
      if (item.min > this.goodsInfo.price) {
        this.$message({
          message: "Số tiền thanh toán tối thiểu：" + item.min,
          type: "warning",
          customClass: "message-zindex",
        });
        return;
      }
      this.$router.push({
        path: `${this.$route.path}/confirmPay`,
        query: {
          game_id: this.goodsInfo.game_id,
          goods_id: this.goodsInfo.id,
          price: this.goodsInfo.price,
          pay_type: item.pay_type,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.pop-content-header {
  background: rgb(247, 247, 247);
  border-bottom: 1px solid rgb(229, 229, 234);

  .pop-content-header-text {
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    width: 768px;
    position: relative;
    padding: 0px;

    .pop-content-header-text-info {
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      align-items: center;

      h3 {
        max-width: 80%;
        font-size: 16px;
        text-align: left;
        margin: 0px 0px -1px;
        padding: 15px 0px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      img {
        width: 32px;
        cursor: pointer;
      }
    }
  }
}

.pop-content-container {
  position: relative;
  margin-bottom: 50px;

  .pop-content-container-info {
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 768px;

    .pop-content-container-info-text {
      margin-left: -15px;
      margin-right: -15px;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: auto;
      min-height: 70px;
      max-height: 682px;

      .pop-content-info {
        position: relative;
        margin-right: 0px;
        margin-bottom: 0px;
        min-height: 70px;
        max-height: 682px;
        display: block;

        .pop-content-info-price {
          padding: 8px 16px 16px;
          margin: 0px auto;
          box-sizing: border-box;
          color: rgb(87, 87, 87);
          border-collapse: collapse;

          .td-left {
            width: 100px;
            color: rgb(118, 118, 118);
            font-weight: normal;
            vertical-align: top;
            padding: 10px 0px 10px 5px;
          }

          .td-right {
            text-align: left;
            font-weight: bold;
            font-size: 14px;
            padding: 10px 0px 10px 5px;
          }
        }
      }
    }
  }

  .pop-content-container-pay {
    position: relative;
    // display: flex;
    // -webkit-box-pack: justify;
    // justify-content: space-between;
    border-bottom: 1px solid rgb(242, 242, 242);

    h3 {
      padding: 15px 0px;
      font-size: 17px;
      margin: 0px;
      font-weight: 700;
    }

    .pop-content-container-pay-item {
      position: relative;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      padding: 10px 15px;
      background-color: rgb(255, 255, 255);
      font-size: 12px;
      border-top: none;

      &:hover {
        background-color: #fff2ec;
        cursor: pointer;
      }

      &:not(:first-child) {
        border-top: 1px solid rgb(224, 224, 224);
      }

      .pop-content-container-pay-img {
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        img{
          width: 44px;
        }
      }

      .pop-content-container-pay-info {
        display: flex;
        flex-wrap: wrap;
        -webkit-box-align: center;
        align-items: center;
        color: rgb(0, 0, 0);
        padding-left: 10px;
        width: 115px;

        p {
          width: 100%;
          margin: 5px 0px 0px;
        }

        p:first-child {
          margin-top: 0px;
        }

        span {
          color: rgb(117, 117, 117);
        }
      }

      .pop-content-container-pay-label {
        display: flex;
        flex-wrap: wrap;
        -webkit-box-align: center;
        align-items: center;
        margin-top: -10px;
        max-height: 65px;
        max-width: 50%;
        overflow: hidden;

        .label {
          margin-left: 10px;
          margin-top: 10px;
          display: inline-block;
          padding: 0px 6px;
          line-height: 20px;
          border-radius: 4px;
          border: 1px solid rgb(255, 0, 0);
          color: rgb(255, 0, 0);
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .pop-content-header .pop-content-header-text {
    width: 100%;
    padding-left: 10px;
    padding-right: 0px;
  }

  .pop-content-container .pop-content-container-info {
    width: 100%;
    padding-left: 10px;
    padding-right: 0px;
  }
}
</style>
